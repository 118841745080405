import '../styles/global.css'
import type { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { appWithTranslation } from 'next-i18next'
import localFont from 'next/font/local'
import { Metadata } from 'next'

export const metadata: Metadata = {
  title: 'Adamant Systems',
  description:
    'Компания Adamant Systems создает цифровые продукты и сервисы, которые доставляют ценность людям и бизнесу',
}

const queryClient = new QueryClient()

const myFont = localFont({
  src: [
    {
      path: './Mulish-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: './Mulish-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
  ],
})

function App({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <main className={myFont.className}>
        <Component {...pageProps} />
      </main>
    </QueryClientProvider>
  )
}

export default appWithTranslation(App)
